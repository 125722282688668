import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {}

  displayCompanyName(event) {
    event.preventDefault();
    console.log("Company Name");
    this.reloadForm('display_company_name');
  }

  displayPersonalName(event) {
    event.preventDefault();
    console.log("Personal Name");
    this.reloadForm('display_personal_names');
  }

  reloadForm(param) {
    const form = this.element.querySelector("form");
    const hiddenField = document.createElement("input");
    Object.assign(hiddenField, {
      type: "hidden",
      name: param,
      value: 1,
    });

    form.appendChild(hiddenField);
    form.requestSubmit();
  }
}
